import { useState, useEffect } from "react";
import baseURL from "../Components/url";

const useVentas = () => {
  const [loading, setLoading] = useState(false); // Estado de carga
  const [error, setError] = useState(null); // Estado de error
  const [ventas, setVentas] = useState([]); // Estado para almacenar los datos de ventas

  const fetchVentas = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`${baseURL}/ventasGet.php`);
      const { pedidos } = await response.json();
      setVentas(pedidos);
      setLoading(false);
    } catch (err) {
      setError("Error de conexión");
    }
  };

  return { fetchVentas, loading, error, ventas };
};

export default useVentas;
