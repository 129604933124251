import Header from "../Header/Header";
import HeaderDash from "../../Components/Admin/HeaderDash/HeaderDash";
import VentasData from "../../Components/Admin/Ventas/VentasData";

export default function Usuarios() {
  return (
    <div className="containerGrid">
      <Header />
      <section className="containerSection">
        <HeaderDash />
        <div className="container">
          <VentasData />
        </div>
      </section>
    </div>
  );
}
