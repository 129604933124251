import { useState, useEffect } from "react";
import baseURL from "../Components/url";

const useVenta = () => {
  const [loading, setLoading] = useState(false); // Estado de carga
  const [error, setError] = useState(null); // Estado de error
  const [data, setData] = useState([]); // Estado para almacenar los datos de ventas

  const fetchVenta = async (venta) => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`${baseURL}/ventaGet.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          venta: venta,
        }),
      });
      const { pedido } = await response.json();
      setData(pedido);
      setLoading(false);
    } catch (err) {
      setError("Error de conexión");
    }
  };

  return { fetchVenta, loading, error, data };
};

export default useVenta;
