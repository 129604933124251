import { useEffect, useState } from "react";
import baseURL from "../../url";
import { Link as Anchor } from "react-router-dom";
import useVentas from "../../../hooks/useVentas";

import "./VentasMain.css";

export default function VentasMain() {
  const { loading, error, fetchVentas, ventas } = useVentas();

  useEffect(() => {
    fetchVentas();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div className="table-containerVentas">
      <div className="deFlexMore">
        <h3>Ultimos {ventas?.length} registros</h3>
        <Anchor to={`/dashboard/ventas`} className="logo">
          Ver más
        </Anchor>
      </div>
      <table className="table">
        <thead>
          <tr className="p-2">
            <th>idPedido</th>
            <th>idUsuario</th>
            <th>Total</th>
            <th>Fecha</th>
            <th>Opcion de envio</th>
            <th>Id de opción de envio</th>
            <th>Estado</th>
            <th>External reference</th>
          </tr>
        </thead>
        <tbody>
          {ventas.map((venta) => (
            <tr key={venta.idPedido} className="color-row">
              <td>{venta.idPedido}</td>
              <td>{venta.idUsuario}</td>
              <td>{venta.total}</td>
              <td>{venta.fechaPedido}</td>
              <td>{venta.opcionEnvio}</td>
              <td>{venta.idOpcionEnvio}</td>
              <td>{venta.estado}</td>
              <td>{venta.external_reference}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
