import "./Modal.css";

export default function Modal({ isOpen, onClose, data }) {
  console.log(data);
  if (!isOpen || !data) return null;

  // Función para renderizar valores
  const renderValue = (value) => {
    if (typeof value === "object") {
      // Si es un array de objetos, se muestra como tabla
      if (Array.isArray(value)) {
        return (
          <table className="table">
            <thead>
              <tr>
                {Object.keys(value[0]).map((key) => (
                  <th key={key}>{key}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {value.map((item, index) => (
                <tr key={index}>
                  {Object.values(item).map((val, i) => (
                    <td key={i}>{val.toString()}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        );
      }

      // Si es un objeto, se muestra como una tabla de clave/valor
      return (
        <table className="modal-subtable">
          <tbody>
            {Object.entries(value).map(([key, subValue]) => (
              <tr key={key}>
                <td className="modal-table-key">{key}</td>
                <td className="modal-table-value">{subValue.toString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      );
    }

    // Si es un valor simple
    return value.toString();
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Detalles del Pedido</h2>

        <table className="modal-subtable">
          <tbody>
            {Object.entries(data).map(([key, value]) => (
              <tr key={key}>
                <td>{renderValue(value)}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <button onClick={onClose} className="modal-close-btn">
          Cerrar
        </button>
      </div>
    </div>
  );
}
