import { useEffect, useState } from "react";
import Modal from "../../modal/Modal";
import { Link as Anchor } from "react-router-dom";
import useVentas from "../../../hooks/useVentas";
import useVenta from "../../../hooks/useVenta";
import baseURL from "../../url";

export default function VentasMain() {
  const { loading, error, fetchVentas, ventas } = useVentas();
  const { fetchVenta } = useVenta();

  const [modalData, setModalData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    fetchVentas();
    fetchVenta();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  const handlePedido = async (venta) => {
    try {
      const fetchData = await fetch(`${baseURL}/ventaGet.php`, {
        method: "POST",
        body: JSON.stringify({ venta: venta }),
      });

      const infoPedido = await fetchData.json();
      setModalData(infoPedido);
      setIsModalOpen(true);
    } catch (error) {
      console.error("Error al obtener el pedido:", error);
    }
  };

  return (
    <>
      <table className="table">
        <thead>
          <tr className="p-2">
            <th>idPedido</th>
            <th>idUsuario</th>
            <th>Total</th>
            <th>Fecha</th>
            <th>Opcion de envio</th>
            <th>Id de opción de envio</th>
            <th>Estado</th>
            <th>External reference</th>
          </tr>
        </thead>
        <tbody>
          {ventas.map((venta) => (
            <tr key={venta.idPedido} onClick={() => handlePedido(venta)}>
              <td>{venta.idPedido}</td>
              <td>{venta.idUsuario}</td>
              <td>{venta.total}</td>
              <td>{venta.fechaPedido}</td>
              <td>{venta.opcionEnvio}</td>
              <td>{venta.idOpcionEnvio}</td>
              <td>{venta.estado}</td>
              <td>{venta.external_reference}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Modal */}
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        data={modalData}
      />
    </>
  );
}
